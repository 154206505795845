import React, { Component } from "react";
import LandingPage from "./pages/landing-page";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import VerifyLink from "./pages/verify-page";
export class App extends Component {
  render() {
    return (
      <>
        <Router>
          <Switch>
            <Route path="/" exact component={LandingPage} />
            <Route path="/verifyData" component={VerifyLink} />
          </Switch>
        </Router>
      </>
    );
  }
}

export default App;
