import React, { Component } from "react";
import CryptoJS from "react-native-crypto-js";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import data from "./../../api";
import { store } from "react-notifications-component";
import "./../../App.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);
const {
  listenForUserData,
  listenForServiceProviderResponse,
  generateqr,
  verifyCreditnals,
} = data;

let showNotification = true;

class LandingPage extends Component {
  state = {
    showSpinner: false,
    showBtn: true,
    requestNo: "",
    qrcode: false,
    response: false,
    email: "",
    mno: "",
    profileDrop: false,
    btnText: "Login with Earth Id",
    showText: true,
    showModal: true,
    modalHeading: false,
  };

  updateNotificationState = () => {
    setTimeout(() => {
      showNotification = true;
    }, 100);
    this.redirectToGenerateCred();
  };

  verifyNotificationState = () => {
    setTimeout(() => {
      showNotification = true;
    }, 100);
    this.redirectToVerifyCreditnals();
  };

  userDenied = () => {
    this.setState({
      response: false,
      showModal: false,
    });
    setTimeout(() => {
      document.getElementById("exampleModal").click();
      if (showNotification === true) {
        showNotification = false;
        store.addNotification({
          title: "Error!",
          message: `User Didn't Authorize!`,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true,
          },
        });
        //  this.updateNotificationState();
      }
    }, 0);
  };

  listenForSocket = () => {
    listenForServiceProviderResponse((err, res) => {
      this.setState({
        qrcode: false,
        response: false,
        showSpinner: true,
        showText: false,
      });
    });

    listenForUserData(async (err, res) => {
      let bytes = res.userDetails || res.newreq;
      console.log("bytes", bytes);
      console.log("bytes", `${this.props.location}${bytes}`);
      if (bytes.permission === "denied" || bytes === "denied") {
        this.userDenied();
      } else {
        Swal.fire({
          // title: 'Are you sure?',
          text: bytes.credential,
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Verify",
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire({
              position: "center",
              //icon: 'success',
              title: "Verifying....",
              showConfirmButton: false,
            });
            verifyCreditnals(
              bytes.credential,
              bytes.publicKey,
              bytes.signature,
              bytes.verifyParams
            ).then((data) => {
              if (data.success === true) {
                Swal.fire("Successfully Verified");
              } else {
                Swal.fire("Verification Failed");
              }
            });
          }
        });
        this.setState({
          showQr: false,
          showSpinner: false,
        });
        if (bytes != null) {
          this.setState({
            response: true,
            showSpinner: false,
            loggedIn: true,
            showModal: false,
            btnText: "LogOut",
          });
          setTimeout(() => {
            document.getElementById("exampleModal").click();
            let popMessage;
            if (showNotification === true) {
              if (bytes === "credentialsgenerated") {
                popMessage = "Credentials Generated";
              } else {
                popMessage = `Credentials Received Successfully`;
              }
              showNotification = false;
              store.addNotification({
                title: "Success!",
                message: popMessage,
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                  duration: 3000,
                  onScreen: true,
                },
              });
              if (bytes == "login") {
                this.updateNotificationState();
              } else if (bytes === "verifylogin") {
                this.verifyNotificationState();
              }
            }
          }, 0);
        } else {
          this.setState({
            response: false,
            showModal: false,
          });
          setTimeout(() => {
            document.getElementById("exampleModal").click();
            if (showNotification === true) {
              showNotification = false;
              store.addNotification({
                title: "Error!",
                message: `User Didn't Authorize!`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                  duration: 3000,
                  onScreen: true,
                },
              });
            }
          }, 0);
        }
      }
    });
  };
  verifyIccaQrCodeData = () => {
    generateqr("shareIccaCredentials")
      .then((data) => {
        this.listenForSocket();
        this.setState({
          showQr: true,
          qrCodeData: data,
          apiKey: this.state.apikey,
          qrcode: false,
        });
      })
      .catch((err) => {
        alert(err);
      });
  };
  verifyQrCodeData = () => {
    generateqr("shareCredentials")
      .then((data) => {
        this.listenForSocket();
        this.setState({
          showQr: true,
          qrCodeData: data,
          apiKey: this.state.apikey,
          qrcode: false,
        });
      })
      .catch((err) => {
        alert(err);
      });
  };

  qrcode = () => {
    let { qrCodeData } = this.state;
    return (
      <div className="qr-container">
        <img src={qrCodeData} alt="new" />
      </div>
    );
  };

  redirectToHome() {
    window.location.href = "/";
  }

  redirectToVerifyCreditnals() {
    window.location.href = "/verifyCreditnals";
  }

  render() {
    let showButton = this.state.showSpinner ? (
      <div className="spinner-div">
        <img className="spinner" src={require("./../../spinner.gif")} />
      </div>
    ) : null;
    return (
      <div className="App">
        <nav className="navbar navbar-expand-lg navbar-light">
          <div
            className="collapse navbar-collapse pull-right container"
            id="navbarTogglerDemo01"
          >
            <div className="clickable" onClick={() => this.redirectToHome()}>
              <img
                src={require("./../../fcart2.png")}
                alt="logo"
                className="logo"
              />{" "}
              <span className="logo-text">&nbsp;Verifier</span>
            </div>
            <ul className="navbar-nav mr-auto mt-2 mt-lg-0"></ul>

            {localStorage.user_name ? (
              <div>
                <label className="welcomeLabel">{`Welcome, ${localStorage.getItem(
                  "user_name"
                )}`}</label>
              </div>
            ) : null}

            <div className="profileAccess">
              {window.location.pathname == "/" ? (
                <div>
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-toggle="modal"
                    data-target="#exampleModal"
                    onClick={this.verifyQrCodeData}
                  >
                    Verify Credentials
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-toggle="modal"
                    data-target="#exampleModal"
                    onClick={this.verifyIccaQrCodeData}
                    style={{ marginLeft: "15px" }}
                  >
                    Verify ICCA Credentials
                  </button>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </nav>
        <header>
          {window.location.pathname == "/" ? (
            <div className="hero-section">
              <div className="shoppingCart"></div>
              <div
                className="modal fade"
                id="exampleModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog" role="document">
                  {
                    <div>
                      {this.state.showModal ? (
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4>
                              {this.state.modalHeading &&
                              !this.state.showSpinner
                                ? "Use Earth ID app to scan the QR code"
                                : "Waiting for user authorization"}
                            </h4>
                          </div>
                          <div className="modal-body">
                            <div className="pop-upbox">
                              {showButton}
                              {this.state.response
                                ? null
                                : this.state.qrcode
                                ? this.generateQrCodeData()
                                : null}
                              {this.state.showQr ? (
                                this.qrcode()
                              ) : this.state.showSpinner ? (
                                <img
                                  className="spinner"
                                  src={require("./../../spinner.gif")}
                                />
                              ) : null}
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary p-btn"
                              data-dismiss="modal"
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  }
                </div>
              </div>
            </div>
          ) : window.location.pathname == "/verifyCreditnals" ? (
            <div className="hero-section">
              <div className="shoppingCart"></div>
              <div
                className="modal fade"
                id="exampleModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog" role="document">
                  {
                    <div>
                      {this.state.showModal ? (
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4>
                              {this.state.modalHeading &&
                              !this.state.showSpinner
                                ? "Use Earth ID app to scan the QR code"
                                : "Waiting for user authorization"}
                            </h4>
                          </div>
                          <div className="modal-body">
                            <div className="pop-upbox">
                              {showButton}
                              {this.state.response
                                ? null
                                : this.state.qrcode
                                ? this.verifyQrCodeData()
                                : null}
                              {this.state.showQr ? (
                                this.qrcode()
                              ) : this.state.showSpinner ? (
                                <img
                                  className="spinner"
                                  src={require("./../../spinner.gif")}
                                />
                              ) : null}
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary p-btn"
                              data-dismiss="modal"
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  }
                </div>
              </div>
            </div>
          ) : (
            <div className="hero-section">
              <div className="shoppingCart"></div>
              <div
                className="modal fade"
                id="exampleModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog" role="document">
                  {
                    <div>
                      {this.state.showModal ? (
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4>
                              {this.state.modalHeading &&
                              !this.state.showSpinner
                                ? "Use Earth ID app to scan the QR code"
                                : "Waiting for user authorization"}
                            </h4>
                          </div>
                          <div className="modal-body">
                            <div className="pop-upbox">
                              {showButton}
                              {this.state.response
                                ? null
                                : this.state.qrcode
                                ? this.generateCredQrCodeData()
                                : null}
                              {this.state.showQr ? (
                                this.qrcode()
                              ) : this.state.showSpinner ? (
                                <img
                                  className="spinner"
                                  src={require("./../../spinner.gif")}
                                />
                              ) : null}
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary p-btn"
                              data-dismiss="modal"
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  }
                </div>
              </div>
            </div>
          )}
        </header>
      </div>
    );
  }
}

export default LandingPage;
