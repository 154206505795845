import axios from "axios";
import openSocket from "socket.io-client";
export const isTestnet = true;
const api =
  isTestnet === true
    ? "https://api-stage.myearth.id"
    : "https://api.myearth.id";
const socketURL =
  isTestnet === false
    ? "https://socket-stage.myearth.id"
    : "https://socket.myearth.id";
const socket = openSocket(socketURL, {
  transports: ["websocket", "polling", "flashsocket"],
});
let apiAuthorization = "fae2622d-7b73-4fc6-a536-202cabe75187";

let socketId;
socket.on("connect", () => {
  socketId = socket.id;
  console.log(`I'm connected with the back-end`, socketId);
});
const apiKey = "NEPYGTREFHYPVTO";
const ssiBaseUrl = "https://ssi-verifier.myearth.id";
const authorizationKey = "Api-Key 01a41742-aa8e-4dd6-8c71-d577ac7d463c";
const externalFunctions = {
  /**
   * Returns API Key for vendor
   *
   */
  async getApiKey() {
    return apiKey;
  },
  /**
   * API for generating QR Code
   * @example response
   * qrData=`{"apikey":"werty","encryptionkey":"1234567","reqNo":"qwertyuuytr","sessionKey":"wertyuytresd" }`
   */
  async verifyCreditnals(
    userCredential,
    userPublicKey,
    userSignature,
    userVerifyParams
  ) {
    console.log(userCredential, userPublicKey, userSignature, userVerifyParams);
    let data = [];
    let verifyUrl;
    if (userVerifyParams) {
      var keys = Object.keys(userVerifyParams);
      console.log("keys", keys);
      var value = Object.values(userVerifyParams);
      for (var i = 0; i < keys.length; i++) {
        for (var j = 0; j < value.length; j++) {
          if (i == j) {
            data.push(keys[i] + "%3D" + value[j]);
          }
          console.log("data", data);
        }
      }
    }
    if (data.length > 0) {
      var config = {
        headers: {
          "Content-Type": "application/json",
          "X-API-KEY": authorizationKey,
          publicKey: userPublicKey,
          signature: userSignature,
        },
        params: {
          credentials: userCredential,
        },
      };
      verifyUrl = `${ssiBaseUrl}/api/verifier/verify?verifyParams=${data}`;
    } else {
      var config = {
        headers: {
          "Content-Type": "application/json",
          "X-API-KEY": authorizationKey,
          publicKey: userPublicKey,
          signature: userSignature,
        },
        params: {
          credentials: userCredential,
        },
      };
      verifyUrl = `${ssiBaseUrl}/api/verifier/verify`;
    }
    return new Promise((resolve, reject) => {
      try {
        axios
          .get(verifyUrl, config)
          .then(function (response) {
            resolve(response.data);
            console.log(JSON.stringify(response.data.success));
          })
          .catch(function (error) {
            resolve(error);
            console.log(error);
          });
      } catch (e) {
        console.log("Error while getJourneyResult: ", e);
        reject(e);
      }
    });
  },

  async generateqr(requestType) {
    return new Promise((resolve, reject) => {
      // console.log("apiKey ", apiKey)
      try {
        if (!apiKey) {
          return new Error("apiKey not availaible");
        }
        if (!socketId) {
          return new Error("socketId not availaible");
        }
        let config = {
          headers: {
            authkey: apiAuthorization,
          },
        };
        //get session key from server and store the socketID with session key in db , to be used further during service Provider API to emit
        axios
          .get(
            `${api}/ssi/generateQrCode?apiKey=${apiKey}&socketId=${socketId}&requestType=${requestType}`,
            config,
            { crossdomain: true }
          )
          .then((response) => {
            console.log("response", response);
            if (response.data.code === 400) {
              let errorString = response.data.message
                ? response.data.message
                : "Something went wrong";
              return reject(errorString);
            }
            return resolve(response.data.result);
          })
          .catch((e) => {
            console.log("This is e ", e);
            return reject("unable to get secret Token");
          });
      } catch (e) {
        console.log("Error in qr Code ", e);
        return reject(e);
      }
    });
  },
  /**
     * Start Listening for service provider name
     * @param {* function } cb callback for storing response data
     * @example O/P Successfull
     * {
            "serviceProvider": "FCart ",
        }
        @example O/P Unsuccessfull 
        Error object
     */
  async listenForServiceProviderResponse(cb) {
    socket.on(`sendServiceProvider`, (data) => {
      cb(null, data);
    });
  },
  /**
   * Socket listening for user Data after approval from user through app
   * @param {*} cb callback for data response
   * @returns {*function} returns a callback with newreq object
   * @example
   * // Response successfull
   * newreq:{
   * "pressed":false,
   * "userEmail":"srvo@gmail.com",
   * "userMobileNo":"+916361887698",
   * "fname":"Sarvottam",
   * "dob":"05121993",
   * "emailVerified":true
   * "mobileVerified":false
   * "score":250
   * }
   * //Unsuccessfull
   * * newreq:{
   * "pressed":true,
   * }
   */
  async listenForUserData(cb) {
    console.log("listenForUserDataResponse  ");
    socket.on(`userdata`, (data) => {
      if (data && !data.error) {
        console.log("listenForUserData : ", data);
        cb(null, data);
      }
    });
  },
};
export default externalFunctions;
