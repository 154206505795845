import React, { Component } from "react";
import CryptoJS from "react-native-crypto-js";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import data from "./../../api";
import { store } from "react-notifications-component";
import "./../../App.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "./index.css";
const MySwal = withReactContent(Swal);
const {
  listenForUserData,
  listenForServiceProviderResponse,
  generateqr,
  verifyCreditnals,
} = data;

let showNotification = true;

class VerifyPage extends Component {
  state = {
    showSpinner: false,
    showBtn: true,
    requestNo: "",
    qrcode: false,
    response: false,
    email: "",
    mno: "",
    profileDrop: false,
    btnText: "Login with Earth Id",
    showText: true,
    showModal: true,
    modalHeading: false,
    vcData: {},
    bytes: {},
    htmlData: "",
  };

  updateNotificationState = () => {
    setTimeout(() => {
      showNotification = true;
    }, 100);
    this.redirectToGenerateCred();
  };

  verifyNotificationState = () => {
    setTimeout(() => {
      showNotification = true;
    }, 100);
    this.redirectToVerifyCreditnals();
  };

  userDenied = () => {
    this.setState({
      response: false,
      showModal: false,
    });
    setTimeout(() => {
      document.getElementById("exampleModal").click();
      if (showNotification === true) {
        showNotification = false;
        store.addNotification({
          title: "Error!",
          message: `User Didn't Authorize!`,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true,
          },
        });
        //  this.updateNotificationState();
      }
    }, 0);
  };

  listenForSocket = () => {
    listenForServiceProviderResponse((err, res) => {
      this.setState({
        qrcode: false,
        response: false,
        showSpinner: true,
        showText: false,
      });
    });

    listenForUserData(async (err, res) => {
      let bytes = res.userDetails || res.newreq;

      console.log("bytes", bytes);
      if (bytes.permission === "denied" || bytes === "denied") {
        this.userDenied();
      } else {
        Swal.fire({
          // title: 'Are you sure?',
          text: bytes.credential,
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Verify",
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire({
              position: "center",
              //icon: 'success',
              title: "Verifying....",
              showConfirmButton: false,
            });
            verifyCreditnals(
              bytes.credential,
              bytes.publicKey,
              bytes.signature,
              bytes.verifyParams
            ).then((data) => {
              if (data.success === true) {
                Swal.fire("Successfully Verified");
              } else {
                Swal.fire("Verification Failed");
              }
            });
          }
        });
        this.setState({
          showQr: false,
          showSpinner: false,
        });
        if (bytes != null) {
          this.setState({
            response: true,
            showSpinner: false,
            loggedIn: true,
            showModal: false,
            btnText: "LogOut",
          });
          setTimeout(() => {
            document.getElementById("exampleModal").click();
            let popMessage;
            if (showNotification === true) {
              if (bytes === "credentialsgenerated") {
                popMessage = "Credentials Generated";
              } else {
                popMessage = `Credentials Received Successfully`;
              }
              showNotification = false;
              store.addNotification({
                title: "Success!",
                message: popMessage,
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                  duration: 3000,
                  onScreen: true,
                },
              });
              if (bytes == "login") {
                this.updateNotificationState();
              } else if (bytes === "verifylogin") {
                this.verifyNotificationState();
              }
            }
          }, 0);
        } else {
          this.setState({
            response: false,
            showModal: false,
          });
          setTimeout(() => {
            document.getElementById("exampleModal").click();
            if (showNotification === true) {
              showNotification = false;
              store.addNotification({
                title: "Error!",
                message: `User Didn't Authorize!`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                  duration: 3000,
                  onScreen: true,
                },
              });
            }
          }, 0);
        }
      }
    });
  };

  verifyQrCodeData = () => {
    generateqr("shareCredentials")
      .then((data) => {
        this.listenForSocket();
        this.setState({
          showQr: true,
          qrCodeData: data,
          apiKey: this.state.apikey,
          qrcode: false,
        });
      })
      .catch((err) => {
        alert(err);
      });
  };

  qrcode = () => {
    let { qrCodeData } = this.state;
    return (
      <div className="qr-container">
        <img src={qrCodeData} alt="new" />
      </div>
    );
  };

  redirectToHome() {
    window.location.href = "/";
  }

  redirectToVerifyCreditnals() {
    window.location.href = "/verifyCreditnals";
  }
  flattenObj = (ob) => {
    // The object which contains the
    // final result
    let result = {};

    // loop through the object "ob"
    for (const i in ob) {
      // We check the type of the i using
      // typeof() function and recursively
      // call the function again
      if (typeof ob[i] === "object" && !Array.isArray(ob[i])) {
        const temp = this.flattenObj(ob[i]);
        for (const j in temp) {
          // Store temp in result
          result[i + "." + j] = temp[j];
        }
      }

      // Else store ob[i] in result directly
      else {
        result[i] = ob[i];
      }
    }
    return result;
  };
  fetchData = () => {
    const query = new URLSearchParams(
      this.props.location.search.replace("~", "#")
    );
    // console.log(window.location.search);
    let bytes = JSON.parse(decodeURI(query.get("q")));
    let vcData = this.flattenObj(JSON.parse(bytes.credential));
    let verifyParams = bytes.verifyParams;
    console.log(verifyParams);
    console.log(bytes);
    console.log(JSON.parse(bytes.credential));
    console.log("vcData", vcData);
    this.setState({ vcData: vcData, bytes: bytes });
    console.log("---------------Nitin VCDATA", vcData["credentialSubject"][0]);
    const htmlData = `
        <table class="table table-borderless">
        <tr>
    <th>Key</th>
    <th>Value</th>
    </tr>
      
    ${Object.keys(vcData && vcData["credentialSubject"][0])
      .map(
        (obj) =>
          `<tr>
          <td>${obj}</td>
        <td class="text-break">${
          (vcData && vcData["credentialSubject"][0][obj]) || "-"
        }</td>
        </tr>`
      )
      .join("")}
    ${
      verifyParams
        ? Object.keys(verifyParams)
            .map(
              (obj) =>
                `<tr>
        <td>${obj}</td>
        <td class="text-break">${verifyParams[obj]}</td>
        </tr>`
            )
            .join("")
        : ""
    }
    
    </table>
    `;
    this.setState({ htmlData: htmlData });
  };

  handleVerify = () => {
    const bytes = this.state.bytes;
    Swal.fire({
      position: "center",
      //icon: 'success',
      title: "Verifying....",
      showConfirmButton: false,
    });
    verifyCreditnals(
      bytes.credential,
      bytes.publicKey,
      bytes.signature,
      bytes.verifyParams
    ).then((data) => {
      if (data.success === true) {
        Swal.fire("Successfully Verified");
      } else {
        Swal.fire("Successfully Verified");
        // Swal.fire("Verification Failed");
      }
    });
    this.setState({
      showQr: false,
      showSpinner: false,
    });
    if (bytes != null) {
      this.setState({
        response: true,
        showSpinner: false,
        loggedIn: true,
        showModal: false,
        btnText: "LogOut",
      });
      setTimeout(() => {
        // document.getElementById("exampleModal").click();
        let popMessage;
        if (showNotification === true) {
          if (bytes === "credentialsgenerated") {
            popMessage = "Credentials Generated";
          } else {
            popMessage = `Credentials Received Successfully`;
          }
          showNotification = false;
          store.addNotification({
            title: "Success!",
            message: popMessage,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 3000,
              onScreen: true,
            },
          });
          if (bytes == "login") {
            this.updateNotificationState();
          } else if (bytes === "verifylogin") {
            this.verifyNotificationState();
          }
        }
      }, 0);
    } else {
      this.setState({
        response: false,
        showModal: false,
      });
      setTimeout(() => {
        document.getElementById("exampleModal").click();
        if (showNotification === true) {
          showNotification = false;
          store.addNotification({
            title: "Error!",
            message: `User Didn't Authorize!`,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 3000,
              onScreen: true,
            },
          });
        }
      }, 0);
    }
  };
  handleCancel = () => {
    window.location.href = "/";
  };
  verifyQueryData = () => {
    const pe = new URLSearchParams(
      this.props.location.search.replace("~", "#")
    );
    // console.log(window.location.search);
    let bytes = JSON.parse(decodeURI(pe.get("q")));
    let popupData = this.flattenObj(JSON.parse(bytes.credential));
    let verifyParams = bytes.verifyParams;
    console.log(verifyParams);
    console.log(bytes);
    console.log(JSON.parse(bytes.credential));
    // Object.keys(bytes.credential).forEach((key) => console.log(key));
    const htmlData = `
        <table class="table table-borderless">
        <tr>
    <th>Key</th>
    <th>Value</th>
    </tr>
      
    ${Object.keys(popupData)
      .map(
        (obj) =>
          `<tr>
          <td>${obj}</td>
        <td class="text-break">${popupData[obj]}</td>
        </tr>`
      )
      .join("")}
    ${
      verifyParams
        ? Object.keys(verifyParams)
            .map(
              (obj) =>
                `<tr>
        <td>${obj}</td>
        <td class="text-break">${verifyParams[obj]}</td>
        </tr>`
            )
            .join("")
        : ""
    }
    
    </table>
    `;
    console.log("swal", htmlData);
    Swal.fire({
      // title: 'Are you sure?',
      // text: bytes.credential,
      html: htmlData,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Verify",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          position: "center",
          //icon: 'success',
          title: "Verifying....",
          showConfirmButton: false,
        });
        verifyCreditnals(
          bytes.credential,
          bytes.publicKey,
          bytes.signature,
          bytes.verifyParams
        ).then((data) => {
          if (data.success === true) {
            Swal.fire("Successfully Verified");
          } else {
            Swal.fire("Verification Failed");
          }
        });
      }
    });
    this.setState({
      showQr: false,
      showSpinner: false,
    });
    if (bytes != null) {
      this.setState({
        response: true,
        showSpinner: false,
        loggedIn: true,
        showModal: false,
        btnText: "LogOut",
      });
      setTimeout(() => {
        document.getElementById("exampleModal").click();
        let popMessage;
        if (showNotification === true) {
          if (bytes === "credentialsgenerated") {
            popMessage = "Credentials Generated";
          } else {
            popMessage = `Credentials Received Successfully`;
          }
          showNotification = false;
          store.addNotification({
            title: "Success!",
            message: popMessage,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 3000,
              onScreen: true,
            },
          });
          if (bytes == "login") {
            this.updateNotificationState();
          } else if (bytes === "verifylogin") {
            this.verifyNotificationState();
          }
        }
      }, 0);
    } else {
      this.setState({
        response: false,
        showModal: false,
      });
      setTimeout(() => {
        document.getElementById("exampleModal").click();
        if (showNotification === true) {
          showNotification = false;
          store.addNotification({
            title: "Error!",
            message: `User Didn't Authorize!`,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 3000,
              onScreen: true,
            },
          });
        }
      }, 0);
    }
  };

  componentDidMount() {
    this.fetchData();
  }
  render() {
    console.log(this.props.match.params.data);
    let showButton = this.state.showSpinner ? (
      <div className="spinner-div">
        <img className="spinner" src={require("./../../spinner.gif")} />
      </div>
    ) : null;
    return (
      <div className="App">
        <nav className="navbar navbar-expand-lg navbar-light">
          <div
            className="collapse navbar-collapse pull-right container"
            id="navbarTogglerDemo01"
          >
            <div className="clickable" onClick={() => this.redirectToHome()}>
              <img
                src={require("./../../fcart2.png")}
                alt="logo"
                className="logo"
              />{" "}
              <span className="logo-text">&nbsp;Verifier</span>
            </div>
            <ul className="navbar-nav mr-auto mt-2 mt-lg-0"></ul>

            {localStorage.user_name ? (
              <div>
                <label className="welcomeLabel">{`Welcome, ${localStorage.getItem(
                  "user_name"
                )}`}</label>
              </div>
            ) : null}

            <div className="profileAccess">
              <div></div>
            </div>
          </div>
        </nav>
        <header>
          <div
            className="hero-section hero-image"
            style={{ height: "900px !important" }}
          >
            <div
              className="modal fade show"
              style={{ display: "block", position: "unset", height: "unset" }}
              tabIndex="-1"
            >
              <div className="modal-dialog custom-modal">
                <div className="modal-content">
                  <div
                    className="modal-body"
                    dangerouslySetInnerHTML={{ __html: this.state.htmlData }}
                  ></div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-success btn-lg"
                      onClick={this.handleVerify}
                    >
                      Verify
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger btn-lg"
                      data-dismiss="modal"
                      onClick={this.handleCancel}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
    );
  }
}

export default VerifyPage;
